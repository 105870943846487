import createLucideIcon from '../createLucideIcon';

const Eye = createLucideIcon('Eye', [
  [
    'path',
    { d: 'M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z', key: 'rwhkz3' },
  ],
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
]);

export default Eye;
