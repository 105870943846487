import createLucideIcon from '../createLucideIcon';

const List = createLucideIcon('List', [
  ['line', { x1: '8', y1: '6', x2: '21', y2: '6', key: '1kveod' }],
  ['line', { x1: '8', y1: '12', x2: '21', y2: '12', key: '120u6p' }],
  ['line', { x1: '8', y1: '18', x2: '21', y2: '18', key: '1k409v' }],
  ['line', { x1: '3', y1: '6', x2: '3.01', y2: '6', key: '13co06' }],
  ['line', { x1: '3', y1: '12', x2: '3.01', y2: '12', key: '11tec3' }],
  ['line', { x1: '3', y1: '18', x2: '3.01', y2: '18', key: '14wug1' }],
]);

export default List;
