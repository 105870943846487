import createLucideIcon from '../createLucideIcon';

const Bookmark = createLucideIcon('Bookmark', [
  [
    'path',
    { d: 'm19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z', key: '1fy3hk' },
  ],
]);

export default Bookmark;
